import React from 'react'
import 'twin.macro'

import SEO from 'components/shared/seo'
import Layout from 'mx/layout'
import { Section } from 'mx/section'

const NotFoundPage = () => (
  <Layout>
    <Section tw="h-screen flex justify-center">
      <h1 className="h1" tw="font-thin leading-tight md:leading-tighter">
        Page Not Found
      </h1>
    </Section>
  </Layout>
)

export default NotFoundPage

export const Head = () => <SEO description="" title="Page Not Found" />
